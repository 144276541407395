import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import Img from "gatsby-image";
import React from "react";

const Projekt = ({ title, text, fluid, link, details }) => {
    return (
        <a href={link} className={`${link && "hover:cursor-pointer  duration-500 hover:bg-gray-100"} border-b p-5 group `}>
            <div className="grid  sm:grid-cols-2 gap-5">
                <Img fluid={fluid} className="sm:h-40 border" />
                <div className="flex-col space-y-2 flex place-content-between  h-full">
                    <div>
                        <h3 className="text-lg font-medium mb-1">{title}</h3>
                        <p className="blog-sm-text ">{text}</p>
                    </div>
                    <div className="flex justify-between justify-self-end items-end">
                        <div className="flex space-x-1">
                            {details && details.map((d) => (
                                <span className="blog-sm-text bg-gray-100 py-1 px-2">{d}</span>
                            ))}
                        </div>

                        {link && (
                            <div className="">
                                <div className="p-2  rounded">
                                    <ArrowTopRightOnSquareIcon className="stroke-gray-400 h-5 w-5 group-hover:stroke-gray-900" />
                                </div>
                            </div>
                        )}
                    </div>
                </div>

            </div>
        </a>
    )
}

export default Projekt;